import useAppSelector from './useAppSelector'
import useAppDispatch from './useAppDispatch'
import useBack, { useDynamicBack } from './useBack'
import useCinnamonActions from './useCinnamonActions'
import useCinnamonProps from './useCinnamonProps'
import usePushView from './usePushView'
import useModal from './useModal'
import useDialog from './useDialog'
import {
  useAddItemToCart,
  useDeleteItemFromCart,
  useDeleteProductFromCart,
  useFrequentlyBoughtTogether,
  useRecommendedProductsActions,
} from './cart'
import usePlaceOrder from './usePlaceOrder'
import useCreateOrder from './useCreateOrder'
import useLoadDialogValidations from './useLoadDialogValidations'
import useCategoryLazyLoad from './categories/useCategoryLazyLoad'
import useSuggestionItems from './suggestedItems/useSuggestedItems'
import useBackInStock from './useBackInStock'

const useLogAction = () => (text: string) =>
  // eslint-disable-next-line
  console.log('action executed', text)

export {
  useAppSelector,
  useAppDispatch,
  useBack,
  useDynamicBack,
  useCinnamonActions,
  useCinnamonProps,
  useSuggestionItems,
  useCategoryLazyLoad,
  usePushView,
  useModal,
  useDialog,
  useAddItemToCart,
  useDeleteProductFromCart,
  useLogAction,
  usePlaceOrder,
  useCreateOrder,
  useDeleteItemFromCart,
  useLoadDialogValidations,
  useFrequentlyBoughtTogether,
  useRecommendedProductsActions,
  useBackInStock,
}
