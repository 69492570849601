import { IConfig, IPromotion } from '../interfaces'
import { getPromotionFields } from './common/getPromotionFields'
import formatPrice from './formatPrice'
import { ICartProduct } from '../interfaces/cart'

export interface IFormatPromotionMessage {
  limitQuantity?: number
  message: string
  saving?: number
  unitSaving?: number
  initSaving?: number
  amount?: number
  promotion: IPromotion
  config?: IConfig
  placeholders?: {
    discount?: string | number
    value?: string
    product?: string
    quantity?: string
    count?: string
  }
  cartProducts?: ICartProduct[]
}

const minCountValue = 0
const radixParameter = 10

const formatPromotionMessage = (args: IFormatPromotionMessage) => {
  let { message = '' } = args
  const { cartProducts } = args
  const applyFormatPrice = (placeholder: string, price?: number | null) => {
    if (price && message?.includes(placeholder)) {
      message = message.replace(
        placeholder,
        formatPrice({ currency, price, format }),
      )
    }
  }

  const {
    limitQuantity = 0,
    amount = 0,
    saving = 0,
    unitSaving = 0,
    initSaving = 0,
    placeholders = {},
    promotion,
    config,
  } = args
  const currency = config?.options?.currency || config?.options?.currencySymbol
  const format = config?.options?.format
  const {
    discount = '',
    value = '',
    product = '',
    quantity = '',
    count = '',
  } = placeholders

  const {
    customerBuysAmount: buysAmount,
    customerBuysMinimum: buysMinimum,
    customerBuysQuantity: buysQuantity,
    customerBuysMinimumAmount: minimumAmount,
    customerGetsPercentage: getsPercentage,
    customerGetsQuantity: getsQuantity,
    customerBuysItems: buysItems,
  } = getPromotionFields(promotion)
  applyFormatPrice('{{saving}}', saving)
  applyFormatPrice('{{amount}}', amount)
  applyFormatPrice('{{unitSaving}}', unitSaving)
  applyFormatPrice('{{initSaving}}', initSaving)
  applyFormatPrice('{{buysAmount}}', buysAmount)
  applyFormatPrice('{{minimumAmount}}', minimumAmount)
  if (typeof discount === 'number') {
    applyFormatPrice('{{discount}}', discount)
  }

  if (
    count &&
    parseInt(count, radixParameter) === minCountValue &&
    message.includes('{{count}}')
  )
    return ''

  message = message
    .replace('{{value}}', value)
    .replace('{{count}}', count)
    .replace('{{product}}', product)
    .replace('{{quantity}}', quantity)
    .replace('{{discount}}', String(discount))
    .replace('{{percentage}}', String(discount))
    .replace('{{buysMinimum}}', String(buysMinimum))
    .replace('{{buysQuantity}}', String(buysQuantity))
    .replace('{{getsQuantity}}', String(getsQuantity))
    .replace('{{limitQuantity}}', String(limitQuantity))
  if (buysItems?.length > 1 && cartProducts) {
    const baseIndex = 0
    cartProducts
      .filter(
        (currentProduct) =>
          !!buysItems.find((item) => item === currentProduct.sku),
      )
      .forEach((cartProduct, index) => {
        if (index === baseIndex) message = message.concat(cartProduct.name)
        else message = message.concat(` + ${cartProduct.name}`)
      })
  }
  if (getsPercentage && message.includes('{{getsPercentage}}')) {
    message = message.replace(
      '{{getsPercentage}}',
      `${(getsPercentage * 100).toFixed(0)}%`,
    )
  }
  return message
}

export default formatPromotionMessage
