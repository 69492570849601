import { IPromotionUiCart, IPromotionUiCartInput } from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import { PromotionType } from '../../graphQL/commons'

export const getComboPromotionUiDetails = ({
  activePromotion,
  cartProduct,
  config,
  cartProducts,
}: IPromotionUiCartInput): IPromotionUiCart => {
  const texts = config?.texts?.cartPromotions
  const { discounts, promotionalQty } = cartProduct
  const {
    fields: {
      customerBuysQuantity,
      customerGetsPercentage,
      limitQuantity,
      customerBuysItems,
      customerGetsItems,
      customerGetsQuantity,
      type,
    },
    promotion,
    count,
  } = getActivePromotionsFields(activePromotion)
  const [productToBuy] = customerBuysItems || []
  const [productToGet] = customerGetsItems || []
  const isSameProduct =
    customerBuysItems?.length === 1 &&
    customerGetsItems?.length === 1 &&
    productToBuy === productToGet
  const discountIsFullProduct: boolean =
    isSameProduct || !customerGetsPercentage
  const discount = discountIsFullProduct
    ? String(customerGetsQuantity + customerBuysQuantity)
    : `${(customerGetsPercentage * 100).toFixed(0)}%`
  const {
    freeItemsLabel = '',
    comboDifferentProductLabel = '',
    comboDifferentProductPercentageLabel = '',
    defaultSaving = '',
    defaultLimitLabel = '',
    comboPromotionOrigin = '',
  } = texts || {}

  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    saving: discounts,
    amount: discounts,
    placeholders: {
      discount,
      value: String(customerBuysQuantity),
      count: String(promotionalQty),
    },
  }

  let label: string
  let promoOrigin: string | null = null
  if (isSameProduct) {
    label = formatPromotionMessage({
      ...formatArgs,
      message: freeItemsLabel as string,
    })
  } else {
    const defaultLabel = discountIsFullProduct
      ? (comboDifferentProductLabel as string)
      : (comboDifferentProductPercentageLabel as string)
    label = formatPromotionMessage({
      ...formatArgs,
      placeholders: {
        ...formatArgs.placeholders,
        count: String(discountIsFullProduct ? promotionalQty : count),
      },
      message: defaultLabel,
    })
  }

  if (discounts) {
    let result: IPromotionUiCart = {
      label,
      limit: limitQuantity
        ? formatPromotionMessage({
            ...formatArgs,
            limitQuantity,
            message: defaultLimitLabel as string,
          })
        : '',
      saving: formatPromotionMessage({
        ...formatArgs,
        message: defaultSaving as string,
      }),
      type,
    }
    if (
      cartProducts &&
      customerBuysItems.length > 1 &&
      promotion.type === PromotionType.COMBO
    ) {
      promoOrigin = formatPromotionMessage({
        ...formatArgs,
        cartProducts,
        message: comboPromotionOrigin as string,
      })
      result = {
        ...result,
        promoOrigin,
      }
    }
    return result
  }

  return {
    label,
    type,
  }
}
